import { createFeature, createReducer, on } from '@ngrx/store';
import { DialerAgent } from 'src/app/shared/models';
import { DialerAgentActions } from './dialer-agent.actions';

export const dialerAgentFeatureKey = 'dialer-agent';

export interface DialerAgentState {
  agents: DialerAgent[];
  agent: DialerAgent | null;
  loading: boolean;
  error: any;
}

export const initialState: DialerAgentState = {
  agents: [],
  agent: null,
  loading: true,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(
    DialerAgentActions.loadDialerAgents,
    (state): DialerAgentState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    DialerAgentActions.loadDialerAgentsSuccess,
    (state, { agents }): DialerAgentState => ({
      ...state,
      agents: [...state.agents.filter((item) => !agents.find(x => x.id === item.id && x.agency?.id === item?.agency?.id)), ...agents],
      loading: false,
      error: null,
    })
  ),
  on(
    DialerAgentActions.loadDialerAgentsFailure,
    (state, { error }): DialerAgentState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    DialerAgentActions.loadDialerAgent,
    (state): DialerAgentState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    DialerAgentActions.loadDialerAgentSuccess,
    (state, { agent }): DialerAgentState => ({
      ...state,
      agents: [...state.agents.filter((item) => agent.id !== item.id || agent.agency?.id !== item?.agency?.id), agent],
      agent,
      loading: false,
      error: null,
    })
  ),
  on(
    DialerAgentActions.loadDialerAgentFailure,
    (state, { error }): DialerAgentState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    DialerAgentActions.addDialerAgent,
    (state): DialerAgentState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    DialerAgentActions.addDialerAgentSuccess,
    (state, { agent }): DialerAgentState => ({
      ...state,
      agents: [...state.agents, agent],
      loading: false,
      error: null,
    })
  ),
  on(
    DialerAgentActions.addDialerAgentFailure,
    (state, { error }): DialerAgentState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    DialerAgentActions.removeDialerAgent,
    (state): DialerAgentState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    DialerAgentActions.removeDialerAgentSuccess,
    (state, { agentId }): DialerAgentState => ({
      ...state,
      agents: state.agents.filter(agent => agent.id !== agentId || agent.agency?.id !== agent?.agency?.id),
      loading: false,
      error: null,
    })
  ),
  on(
    DialerAgentActions.removeDialerAgentFailure,
    (state, { error }): DialerAgentState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    DialerAgentActions.updateDialerAgent,
    (state): DialerAgentState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    DialerAgentActions.updateDialerAgentSuccess,
    (state, { agent }): DialerAgentState => ({
      ...state,
      agents: state.agents.map(item =>
        // item.id === agent.id ? agent : item
        item.id === agent.id && agent.agency?.id === item?.agency?.id ? { ...item, ...agent } : item
      ),
      loading: false,
      error: null,
    })
  ),
  on(
    DialerAgentActions.updateDialerAgentFailure,
    (state, { error }): DialerAgentState => ({
      ...state,
      loading: false,
      error,
    })
  )
);

export const dialerAgentFeature = createFeature({
  name: dialerAgentFeatureKey,
  reducer,
});
